import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Image,
	Figure,
	A,
	Button,
	List
} from '../components/styled'

export default ({data}) => {
	//console.log(data);
	return (
  <Layout>
    <SEO title="404: Not found" />
  	<Container>
			<Container.Inner>
				<Content full-width center>
  				<Title as="h1">NOT FOUND</Title>
  				{/*<p>You just hit a route that doesn&#39;t exist... the sadness.</p>*/}
  				<List>
  					<List.Item><Button as={Link} to="/leistungen/">Leistungen ansehen</Button></List.Item>
  					<List.Item><Button as={Link} to="/kontakt/">Kontakt aufnehmen</Button></List.Item>
  					<List.Item></List.Item>
  					<List.Item></List.Item>
  				</List>
  			</Content>
  		</Container.Inner>
  	</Container>
  	<Container>
			<Container.Inner>
				<Content full-width center>
  				<Figure>
  					<Image as={Img} fluid={data.image.childImageSharp.fluid} />
  					<Figure.Caption>&copy; <A href="https://unsplash.com/photos/7H6do0tudx0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" external>Tobias Mrzyk / Unsplash</A></Figure.Caption>
  				</Figure>
  			</Content>
  		</Container.Inner>
  	</Container>
  </Layout>
)}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "tobias-mrzyk-569908-unsplash.jpg" }) {
      ...flexImage
    }
  }
`
